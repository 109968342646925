<template>
    <div class="flex flex-col w-full my-64 justify-center items-center">
        <div class="text-2xl">
            Well this is awkward... something went wrong!
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped>
.space-bottom {
    margin-bottom: 200px;
}
</style>
