
.style-panel {
    height: calc(100vh - 150px);
    overflow: scroll;
}

@media (min-width: 768px) {
    .style-panel {
        height: calc(100vh - 200px);
        overflow: scroll;
    }
}
