*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.el-tabs__active-bar {
    background-color: unset;
}

.el-dialog__header {
    padding: unset;
}

.rounded-dialog {
    border-radius: 2rem;
}

.style-link {
}
