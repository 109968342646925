
.blinking {
    animation: blinkingBackground 0.8s infinite;
}
@keyframes blinkingBackground {
    0% {
        --tw-text-opacity: 1;
        color: rgb(196 77 43 / var(--tw-text-opacity));
    }
}
